import { useState, useEffect, useRef } from "react";
import { db } from '../firebase/config'

// firebase imports
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore'

export const useCollection = (c, _q, _o) => {
    const [documents, setDocuments] = useState(null)

    // set up query
    const q = useRef(_q).current

    // set up orderBy
    const o = useRef(_o).current

    useEffect(() => {
        let ref = collection(db, c)

        if (q) {
            ref = query(ref, where(...q))
        }

        // if (o) {
        //     ref = orderBy(ref, orderBy(...o))
        // }

        const unsub = onSnapshot(ref, (snapshot) => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({ id: doc.id, ...doc.data() })
            })
            setDocuments(results)
        })

        return () => unsub()
    }, [c, q, o])

    return { documents }
}