import { useEffect, useState } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, deleteDoc, orderBy } from "firebase/firestore"
import Select from "react-select"
//import { quarterOptions } from "../../Constants"
import { useCollection } from "../../hooks/useCollection"

export default function Benefits() {
  const [selectedQuarter, setSelectedQuarter] = useState(null)
  const [quarterlySleeveBox, setQuarterlySleeveBox] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [quarter, setQuarter] = useState(null)
  const [name, setName] = useState("")
  const [dateReceived, setDateReceived] = useState("")

  const { documents: people } = useCollection('people')
  let peopleOptions = null
  if (people != null) {
    people.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    peopleOptions = people.map(p => {
      return { value: p.name, label: p.name }
    })
  }

  const { documents: quarters } = useCollection('quarters')

  if (quarters !== null) {
    quarters.sort((a, b) => (a.quarterName > b.quarterName) ? 1 : ((b.quarterName > a.quarterName) ? -1 : 0))
  }

  let quarterOptions = null
  if (quarters != null) {
    quarterOptions = quarters.map(q => {
      return { value: q.quarterName, label: q.quarterName + " - " + "(" + q.dateRange + ")" }
    })
  }

  useEffect(() => {
    let ref = collection(db, "benefitQuarterlySleeves")

    if (selectedQuarter !== "") {
      ref = query(ref, where(...["quarter", "==", selectedQuarter]))
    }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })
      results.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      setQuarterlySleeveBox(results)
    })

    return () => unsub()
  }, [selectedQuarter])

  const handleAdd = () => {
    console.log("handleAddEvent")

    if (isEditing) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  const handleSave = async e => {
    e.preventDefault()
    const ref = collection(db, "benefitQuarterlySleeves")

    let dateFormat = new Date(dateReceived)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      name: name.trim(),
      quarter: quarter.trim(),
      dateReceived: Timestamp.fromDate(new Date(dateStr))
    })
      .then(setName(""), setQuarter(""), setDateReceived(""))
      .finally(console.log("added successfully"))
    setIsEditing(false)
  }

  const handleDelete = async e => {
    console.log("handleDelete e:", e)

    if (window.confirm("Delete: " + e.quarter + " for " + e.name + "?") === true) {
      const docRef = doc(db, "benefitQuarterlySleeves", e.id)
      await deleteDoc(docRef)
      console.log("delete confirmed")
    } else {
      console.log("nothing was deleted")
    }
  }

  const handleNotInList = e => {
    const difference = people.filter(ad => 
      quarterlySleeveBox.every(fd => (fd.name !== ad.name && ad.personIsPlayer === "Yes")))
    console.log("difference", difference)
    const differenceMessage = difference.map(d => (
    d.name
    ))
    alert(differenceMessage)
  }

  return (
    <>
      <span className="page-title">
        <h4>Quarterly Sleeves</h4>
        <span style={{ marginLeft: "auto" }}>
          {selectedQuarter && (
          <button className="btn" onClick={handleNotInList}>show missing</button>
          )}
          <button className="btn edit-button" onClick={handleAdd}>
            {isEditing && "cancel"}
            {!isEditing && "add"}
          </button>
        </span>
      </span>
      {!isEditing && (
        <label>
          <span>Filter by Quarter:</span>
          <Select options={quarterOptions} onChange={option => setSelectedQuarter(option.value)} />
        </label>
      )}
      {isEditing && (
        <form onSubmit={handleSave}>
          <label>
            <span>Name:</span>
            {/* <input required type="text" onChange={e => setName(e.target.value)} value={name} /> */}
            <Select required options={peopleOptions} onChange={option => setName(option.value)} />
          </label>
          <label>
            <span>Select Quarter:</span>
            <Select options={quarterOptions} onChange={option => setQuarter(option.value)} />
          </label>
          <label>
            <span>Date Received:</span>
            <input required type="date" onChange={e => setDateReceived(e.target.value)} value={dateReceived} />
          </label>
          <button className="btn">save</button>
        </form>
      )}
      <div className="event-list">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ flexBasis: "25%" }}>Quarter</strong>
          <strong style={{ flexBasis: "25%" }}>Name</strong>
          <strong style={{ flexBasis: "25%" }}>Date Received</strong>
          <strong style={{ flexBasis: "25%" }}></strong>
        </div>
        {quarterlySleeveBox &&
          quarterlySleeveBox.map(s => (
            <div key={s.id} style={{ display: "flex" }}>
              <p style={{ flexBasis: "25%" }}>{s.quarter}</p>
              <p style={{ flexBasis: "25%" }}>{s.name}</p>
              <p style={{ flexBasis: "25%" }}>{s.dateReceived.toDate().toDateString()}</p>
              <button className="btn delete-btn-small" onClick={() => handleDelete(s)}>
                delete
              </button>
            </div>
          ))}
      </div>
    </>
  )
}
