import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import { collection, getDocs } from "firebase/firestore"
import TaskList from "../../components/TaskList"
import { useCollection } from "../../hooks/useCollection"

// styles

export default function Tasks() {
  const { documents: tasks } = useCollection('tasks')

  return (
    <div>
      {/* <h3 className="page-title">Dashboard</h3> */}
      {tasks && <TaskList tasks={tasks} />}
    </div>
  )
}
