import { useEffect, useState, useRef } from "react"
import { db, Timestamp } from "../../firebase/config"
import { collection, addDoc } from "firebase/firestore"
import { v4 as uuidv4 } from 'uuid';

// styles
import "./Create.css"

export default function Create() {
  // form field values
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [dueDate, setDueDate] = useState("")
  //const [error, setError]

  const [subtasks, setSubtasks] = useState([])

  const [newSubtask, setNewSubtask] = useState({})
  const [subtaskTitle, setSubtaskTitle] = useState("")
  const [subtaskDescription, setSubtaskDescription] = useState("")
  const [subtaskAssignedTo, setSubtaskAssignedTo] = useState("")
  const [subtasskOrder, setSubtaskOrder] = useState(0)

  const subtaskTitleInput = useRef(null)

  const handleSubmit = async e => {
    e.preventDefault()
    alert("submit clicked")

    const ref = collection(db, "tasks")

    console.log("subtasks:", subtasks)
    console.log("dueDate", dueDate)
    console.log("new Date(dueDate)", new Date(dueDate))
    console.log("new Date(dueDate).toUTCString()", new Date(dueDate).toUTCString())
    //let utcDate = new Date(dueDate).toUTCString()
    //let dateTest = new Date(dueDate).toUTCString()
    //console.log("dateTest", dateTest)

    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()
    console.log("dateStr", dateStr)

    await addDoc(ref, {
      title: title,
      description: description,
      dueDate: Timestamp.fromDate(new Date(dateStr)),
      status: "Open",
      subtasks: subtasks
    })
  }

  const handleAddSubtask = e => {
    console.log("handleAddSubtask")
    e.preventDefault()

    //setSubtaskCount()

    const newSubtaskTitle = subtaskTitle.trim()
    const newSubtaskDescription = subtaskDescription.trim()
    const newSubtaskAssignedTo = subtaskAssignedTo.trim()
    let newSubtaskOrder = 0
    if (subtasks.length > 0) {
      let arr = subtasks.map(s => {
        return s.order
      })
      console.log("arr", arr)
      newSubtaskOrder = Math.max(...arr)
    }
    
    console.log("newSubtaskOrder",newSubtaskOrder)

    const subtask = { title: newSubtaskTitle, description: newSubtaskDescription, assignedTo: newSubtaskAssignedTo, status: "Open", order: newSubtaskOrder+1, id: uuidv4() }
    console.log("subtask object:", subtask)

    //setNewSubtask({ title: newSubtaskTitle, description: newSubtaskDescription, assignedTo: newSubtaskAssignedTo })

    if (validSubtask(subtask)) {
      setSubtasks(prevSubtasks => [...prevSubtasks, subtask])
      console.log("Subtask added successfully")
    } else {
      console.log("Subtask was NOT added")
    }
  }

  const validSubtask = subtask => {
    console.log("subtask.title", subtask.title)
    if (subtask.title === "") {
      console.log("Subtask Title is required")
      return false
    } else {
      return true
    }
  }

  return (
    <div className="create-form">
      <h2 className="page-title">Create a new task</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Task Title:</span>
          <input required type="text" onChange={e => setTitle(e.target.value)} value={title} />
        </label>
        <label>
          <span>Description:</span>
          <textarea required onChange={e => setDescription(e.target.value)} value={description} />
        </label>
        <label>
          <span>Due Date:</span> {dueDate}
          <input required type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
        </label>
        <button className="btn">submit</button>
        <hr className="solid" />
        <h2 className="page-title">Subtasks:</h2>
        {/* <p>Current ingredients: {subtasks.map(i => <em key={i}>{i.title} {i.description}, </em>)}</p> */}
        <div className="subtasks">
          <label>
            <span>Subtask Title:</span>
            <input type="text" onChange={e => setSubtaskTitle(e.target.value)} value={subtaskTitle} ref={subtaskTitleInput} />
            {subtaskTitle}
            {newSubtask.title}
          </label>
          <label>
            <span>Subtask Description:</span>
            <input type="text" onChange={e => setSubtaskDescription(e.target.value)} value={subtaskDescription} />
          </label>
          <label>
            <span>Subtask Assigned To:</span>
            <input type="text" onChange={e => setSubtaskAssignedTo(e.target.value)} value={subtaskAssignedTo} />
          </label>
          <button onClick={handleAddSubtask} className="btn">
            add
          </button>
          {subtasks.map(i => (
            <div className="subtask-list" key={i.title}>
              <ul>Subtask Title: {i.title}</ul>
              <ul>Subtask Description: {i.description}</ul>
              <ul>Status: {i.status}</ul>
              <ul>Assigned To: {i.assignedTo}</ul>
            </div>
          ))}
        </div>
      </form>
    </div>
  )
}
