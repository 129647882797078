import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import { collection, getDocs } from "firebase/firestore"
import CardLibraryList from "../../components/CardLibraryList"
import { useCollection } from "../../hooks/useCollection"
// styles
//import "./Dashboard.css"

export default function CardLibrary() {
  const { documents: cardLibrary } = useCollection('cardLibrary')

  return (
    <div>
      {/* <h3 className="page-title">Card Library</h3> */}
      {cardLibrary && <CardLibraryList cardLibrary={cardLibrary} />}
    </div>
  )
}
