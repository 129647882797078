export const currentYear = +2024

export const quarterOptions = [
  { value: "2023 Q4", label: "2023 Q4 - (4/1/2023 - 6/30/2023)" },
  { value: "2024 Q1", label: "2024 Q1 - (7/1/2023 - 9/30/2023)" },
  { value: "2024 Q2", label: "2024 Q2 - (10/1/2023 - 12/31/2023)" },
  { value: "2024 Q3", label: "2024 Q3 - (1/1/2024 - 3/31/2024)" }
]

export const statusOptions = [
  { value: "Open", label: "Open" },
  { value: "In Progress", label: "In Progress" },
  { value: "Complete", label: "Complete" },
  { value: "On Hold", label: "On Hold" }
]

export const yesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
]

export const divisionOptions = [
  { value: "Master", label: "Master" },
  { value: "Senior", label: "Senior" }
]

export const eventTypeOptions = [
  { value: "Challenge", label: "Challenge" },
  { value: "Cup", label: "Cup" },
  { value: "Regional", label: "Regional" },
  { value: "International", label: "International" }
]

export const metaAnalysisEventTypeOptions = [
  { value: "Locals", label: "Locals" },
  { value: "Challenge", label: "Challenge" },
  { value: "Cup", label: "Cup" }
]

export const metaAnalysisFormatOptions = [
  { value: "Standard", label: "Standard" },
  { value: "Expanded", label: "Expanded" },
  { value: "GLC", label: "GLC" },
  { value: "Worlds 2024 Format", label: "Worlds 2024 Format" }
]

export const metaAnalysisFills = [
  // 11 defined colors
  "#EC7A08",
  "#0066cc",
  "#C9190B",
  "#4CB140",
  "#5752D1",
  "#73C5C5",
  "maroon",
  "#fc49f9",
  "navy",
  "olive",
  "#adab21"
]

export const metaAnalysisGLCOptions = [
  { value: "Grass", label: "Grass" },
  { value: "Fire", label: "Fire" },
  { value: "Water", label: "Water" },
  { value: "Lightning", label: "Lightning" },
  { value: "Psychic", label: "Psychic" },
  { value: "Fighting", label: "Fighting" },
  { value: "Colorless", label: "Colorless" },
  { value: "Darkness", label: "Darkness" },
  { value: "Metal", label: "Metal" },
  { value: "Dragon", label: "Dragon" },
  { value: "Fairy", label: "Fairy" }
]

export const metaAnalysisGLCColors = {
  Grass: "#00cc00",
  Fire: "#C9190B",
  Water: "#0066cc",
  Lightning: "#F6BE00",
  Psychic: "#8F00FF",
  Fighting: "#622A0F",
  Colorless: "#b5b5b5 ",
  Darkness: "#00202e",
  Metal: "#727982",
  Dragon: "#adab21",
  Fairy: "#fc49f9"
}

export const people = [{ name: "Dan H." }, { name: "Ely G." }, { name: "Evan S." }, { name: "Frank M." }, { name: "Justin T." }, { name: "Michael D." }, { name: "Mo I." }, { name: "Rafal G." }, { name: "Reno E." }, { name: "Ryan P." }]
