import { useEffect, useState, useRef } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore"
import Select from "react-select"
import { yesNoOptions, divisionOptions } from "../../Constants"

export default function PersonAdmin() {
  const [people, setPeople] = useState("")
  const [isAddingPerson, setIsAddingPerson] = useState(false)
  const [editPersonId, setEditPersonId] = useState(null)
  const [personName, setPersonName] = useState("")
  const [personIsPlayer, setPersonIsPlayer] = useState("")
  const [personDivision, setPersonDivision] = useState("")

  useEffect(() => {
    let ref = collection(db, "people")

    // if (selectedQuarter !== "") {
    //   ref = query(ref, where(...["quarter", "==", selectedQuarter]))
    // }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })
      setPeople(results)
    })

    return () => unsub()
  }, [])

  if (people) {
    people.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }

  const handlePersonAdd = () => {
    setEditPersonId(null)
    if (isAddingPerson) {
      setIsAddingPerson(false)
    } else {
      setIsAddingPerson(true)
    }
  }

  const handlePersonAddSubmit = async e => {
    e.preventDefault()

    const ref = collection(db, "people")
    await addDoc(ref, {
      name: personName.trim(),
      personIsPlayer: personIsPlayer.trim(),
      division: personDivision.trim()
    })

    setIsAddingPerson(false)
  }

  const handlePeopleEdit = p => {
    console.log("handlePeopleEdit", p)
    setIsAddingPerson(false)
    setPersonName(p.name)
    setPersonIsPlayer(p.personIsPlayer)
    setPersonDivision(p.division)
    setEditPersonId(p.id)
  }

  const handleCancelPersonEdit = () => {
    setEditPersonId(null)
  }

  const handlePeopleEditSubmit = async e => {
    e.preventDefault()

    const docRef = doc(db, "people", editPersonId)
    console.log("update editPersonId id:", editPersonId)

    const data = {
      name: personName.trim(),
      personIsPlayer: personIsPlayer.trim(),
      division: personDivision.trim()
    }

    updateDoc(docRef, data).then(docRef => {
      console.log("person updated")
    })

    setEditPersonId(null)
  }


  return (
    <>
      <span style={{ display: "flex", marginTop: "1em" }}>
        <p>Person Admin</p>
        <span style={{ marginLeft: "auto" }}>
          <button className="btn" onClick={() => handlePersonAdd()}>
            add
          </button>
        </span>
      </span>
      {isAddingPerson && (
        <form onSubmit={handlePersonAddSubmit}>
          <label>
            <span>Name:</span>
            <input required type="text" onChange={e => setPersonName(e.target.value)} />
          </label>
          <label>
            <span>Player:</span>
            <Select options={yesNoOptions} onChange={option => setPersonIsPlayer(option.value)} />
          </label>
          <label>
            <span>Division:</span>
            <Select options={divisionOptions} onChange={option => setPersonDivision(option.value)} />
          </label>
          <button className="btn">save</button>
        </form>
      )}

      {people &&
        people.map(person => (
          <div className="subtask-list" key={person.id}>
            {editPersonId === person.id && (
              <form onSubmit={handlePeopleEditSubmit}>
                <label>
                  <span>Name:</span>
                  <p>{person.name}</p>
                  {/* <input required type="text" onChange={e => setPersonName(e.target.value)} value={personName} /> */}
                </label>
                <label>
                  <span>Player:</span>
                  <Select options={yesNoOptions} onChange={option => setPersonIsPlayer(option.value)} value={{ value: personIsPlayer, label: personIsPlayer }} />
                </label>
                <label>
                  <span>Division:</span>
                  <Select options={divisionOptions} onChange={option => setPersonDivision(option.value)} value={{ value: personDivision, label: personDivision }} />
                </label>
                <button type="button" className="btn" onClick={() => handleCancelPersonEdit()}>cancel</button>
                <button className="btn" style={{ marginLeft: "6px" }}>save</button>
              </form>
            )}

            {editPersonId !== person.id && (
              <div>
                <span style={{ display: "flex" }}>
                  <p>Name: {person.name}</p>
                  <button className="btn edit-btn-small" onClick={() => handlePeopleEdit(person)}>
                    edit
                  </button>
                </span>
                <p>Player: {person.personIsPlayer}</p>
                <p>Division: {person.division}</p>
              </div>
            )}
          </div>
        ))}
    </>
  )
}