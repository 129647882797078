import { useEffect, useState, useRef } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore"
import Select from "react-select"
import { yesNoOptions, divisionOptions } from "../../Constants"
import AutoGenerateTasksAdmin from "./AutoGenerateTasksAdmin"
import PersonAdmin from "./PersonAdmin"
import { useCollection } from "../../hooks/useCollection"
import QuarterAdmin from "./QuarterAdmin"
import StipendAdmin from "./StipendAdmin"

export default function Admin() {

  //const [isAddingQuarterEvent, setIsAddingQuarterEvent] = useState(false)

  const { documents: autoGenerateTasks } = useCollection("autoGenerateTask")

  const { documents: quarters} = useCollection("quarters")

  const { documents: people } = useCollection("people")


  //quarters.sort((a, b) => (a.quarterName > b.quarterName ? 1 : b.quarterName > a.quarterName ? -1 : 0))

  //const itemEls = useRef(new Array())



  // const handleShowPeopleData = async e => {

  //     console.log("handleShowPeopleData")
  // }

  return (
    <>
      <span className="page-title">
        <h4>Admin</h4>
        <span style={{ marginLeft: "auto" }}>
          {/* <button className="btn" onClick={handleShowPeopleData}>
                        Show People Data
                    </button> */}
        </span>
      </span>

      <StipendAdmin />

      <AutoGenerateTasksAdmin autoGenerateTasks={autoGenerateTasks} people={people} />

      <PersonAdmin />

      <QuarterAdmin quarters={quarters}/>
    </>
  )
}
