import { useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext"

// styles
import "./App.css"

// pages and components
import Dashboard from "./pages/dashboard/Dashboard"
import Navbar from "./components/Navbar"
import Tasks from "./pages/task/Tasks"
import Task from "./pages/task/Task"
// import Sidebar from "./components/Sidebar"
import Create from "./pages/create/Create"
import Schedule from "./pages/schedule/Schedule"
import CardLibrary from "./pages/cardLibrary/CardLibrary"
import EditTask from "./pages/task/EditTask"
import Signup from "./pages/signup/Signup"
import Login from "./pages/login/Login"
import Benefits from "./pages/benefits/Benefits"
import Stipends from "./pages/stipends/Stipends"
import Admin from "./pages/admin/Admin"
import Timer from "./pages/timer/Timer"
import SinglesShowcase from "./pages/singlesShowcase/SinglesShowcase"
import MetaAnalysis from "./pages/metaAnalysis/MetaAnalysis"

function App() {
  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {/* <Sidebar /> */}
          <div className="container">
            <Navbar />
            <div style={{ padding: "0px .2em" }}>
              <Routes>
                <Route path="/" element={user ? <Dashboard /> : <Login />} />
                <Route path="/tasks" element={user ? <Tasks /> : <Login />} />
                {/* <Route path="/signup" element={!user ? <Signup /> : <Dashboard /> } /> */}
                <Route path="/login" element={!user ? <Login /> : <Dashboard />} />
                <Route path="/task/:id" element={user ? <Task /> : <Login />} />
                {/* <Route path="/edittask/:id" element={user ? <EditTask /> : <Login /> } /> */}
                <Route path="/create" element={user ? <Create /> : <Login />} />
                <Route path="/schedule" element={user ? <Schedule /> : <Login />} />
                <Route path="/cardlibrary" element={user ? <CardLibrary /> : <Login />} />
                <Route path="/benefits" element={user ? <Benefits /> : <Login />} />
                <Route path="/stipends" element={user ? <Stipends /> : <Login />} />
                <Route path="/admin" element={user ? <Admin /> : <Login />} />
                <Route path="/timer" element={<Timer />} />
                <Route path="/singles" element={user ? <SinglesShowcase /> : <Login />} />
                <Route path="/metaanalysis" element={user ? <MetaAnalysis /> : <Login />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
