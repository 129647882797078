import { useState } from "react"
import Select from "react-select"
import { db, doc } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore"

export default function AutoGenerateTasksAdmin({ autoGenerateTasks, people }) {
  let peopleOptions = null
  if (people != null) {
    peopleOptions = people.map(p => {
      return { value: p.name, label: p.name }
    })
    peopleOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
  }

  const [id, setId] = useState("")
  const [editIndex, setEditIndex] = useState(null)
  const [editedSubtask, setEditedSubtask] = useState("")
  const [editedSubtaskAssignTo, setEditedSubtaskAssignTo] = useState("")
  const [editedSubtaskDaysBeforeDueDate, setEditedSubtaskDaysBeforeDueDate] = useState("")
  const [isAdding, setIsAdding] = useState(false)
  const [newSubtask, setNewSubtask] = useState("")
  const [newSubtaskAssignTo, setNewSubtaskAssignTo] = useState("")
  const [newSubtaskDaysBeforeDueDate, setNewSubtaskDaysBeforeDueDate] = useState("")

  const handleEdit = (a, index) => {
    setIsAdding(false)
    if (editIndex === null) {
      setEditIndex(index)
      setEditedSubtask(a.subtasks[index].subtaskTitle)
      setEditedSubtaskAssignTo(a.subtasks[index].assignTo)
      setEditedSubtaskDaysBeforeDueDate(a.subtasks[index].daysBeforeDueDate)
      setId(a.id)
    } else {
      setEditIndex(null)
      setEditedSubtask("")
      setEditedSubtaskAssignTo("")
      setEditedSubtaskDaysBeforeDueDate("")
      setId("")
    }
  }

  const handleUpdate = async (a) => {
    if (isAdding) {
      a.subtasks = a.subtasks ? [...a.subtasks] : new Array()

      let newEntry = {}
      newEntry.subtaskTitle = newSubtask
      newEntry.assignTo = newSubtaskAssignTo
      newEntry.daysBeforeDueDate = newSubtaskDaysBeforeDueDate

      a.subtasks.push(newEntry)

      const data = {
        subtasks: a.subtasks
      }

      const docRef = doc(db, "autoGenerateTask", id)

      try {
        updateDoc(docRef, data).then(docRef => {
          console.log("Added")
        })
      } catch (error) {
        console.log("error:", error)
      }
      setIsAdding(false)
      setId("")

    } else {
      a.subtasks[editIndex].subtaskTitle = editedSubtask
      a.subtasks[editIndex].assignTo = editedSubtaskAssignTo
      a.subtasks[editIndex].daysBeforeDueDate = editedSubtaskDaysBeforeDueDate

      const data = {
        subtasks: a.subtasks
      }

      const docRef = doc(db, "autoGenerateTask", id)

      try {
        updateDoc(docRef, data).then(docRef => {
          console.log("Updated")
        })
      } catch (error) {
        console.log("error:", error)
      }

      setId("")
      setEditIndex(null)
      setEditedSubtask("")
      setEditedSubtaskAssignTo("")
      setEditedSubtaskDaysBeforeDueDate("")
    }
  }

  const handleAdd = async (a) => {
    if (isAdding) {
      setIsAdding(false)
    } else {
      setEditIndex(null)
      setEditedSubtask("")
      setEditedSubtaskAssignTo("")
      setIsAdding(true)
      setId(a.id)
    }
  }

  const handleDownRank = (a, index) => {
    let arr = a.subtasks

    const fromIndex = index
    const toIndex = index + 1

    const element = arr.splice(fromIndex, 1)[0]

    arr.splice(toIndex, 0, element)

    const data = {
      subtasks: arr
    }

    const docRef = doc(db, "autoGenerateTask", id)

    try {
      updateDoc(docRef, data).then(docRef => {
        console.log("Updated rank")
      })
    } catch (error) {
      console.log("error:", error)
    }
    setEditIndex(toIndex)
  }

  const handleUpRank = (a, index) => {
    let arr = a.subtasks

    const fromIndex = index
    const toIndex = index - 1

    const element = arr.splice(fromIndex, 1)[0]

    arr.splice(toIndex, 0, element)

    const data = {
      subtasks: arr
    }

    const docRef = doc(db, "autoGenerateTask", id)

    try {
      updateDoc(docRef, data).then(docRef => {
        console.log("Updated rank")
      })
    } catch (error) {
      console.log("error:", error)
    }
    setEditIndex(toIndex)
  }

  return (
    <>
      <p>Auto Generate Tasks Admin</p>
      {autoGenerateTasks &&
        autoGenerateTasks.map(a => (
          <div className="subtask-list" key={a.id}>
            <span style={{ display: "flex", marginBottom: "1em" }}>
              <strong><u>{a.taskTitle}</u></strong>
              <button style={{ marginLeft: "auto", height: "fit-content" }} className="btn edit-btn-small" onClick={() => handleAdd(a)}>add</button>
            </span>
            {a.subtasks &&
              a.subtasks.map((s, index) => (
                <span style={{ display: "flex", marginTop: ".8em" }} key={s.subtaskTitle}>
                  {((editIndex !== index) && (id === a.id) || (id !== a.id)) && (
                    <span style={{}}>
                      <strong>
                        {s.subtaskTitle}
                      </strong>
                      <p>
                        {s.assignTo}
                      </p>
                      <span className="tooltip">
                        {s.daysBeforeDueDate}<span className="tooltiptext">Days before due date</span>
                      </span>
                    </span>
                  )}
                  {editIndex === index && id === a.id && (
                    <form style={{ width: "100%" }}>
                      <span style={{ display: "flex" }}>
                        <input required type="text" onChange={e => setEditedSubtask(e.target.value)} defaultValue={s.subtaskTitle} />
                        <button type="button" className="btn edit-button" style={{ height: "fit-content" }} onClick={() => handleUpRank(a, index)}>
                          {'\u2191'}
                        </button>
                        <button type="button" className="btn edit-button" style={{ height: "fit-content", marginRight: "10px" }} onClick={() => handleDownRank(a, index)}>
                          {'\u2193'}
                        </button>
                      </span>
                      {/* <input required type="text" style={{marginTop: ".5em"}} onChange={e => setEditedSubtaskAssignTo(e.target.value)} defaultValue={s.assignTo} /> */}
                      <label>
                        <span style={{}}>
                          <Select options={peopleOptions} onChange={option => setEditedSubtaskAssignTo(option.value)} defaultValue={{ value: editedSubtaskAssignTo, label: editedSubtaskAssignTo }} />
                        </span>
                      </label>
                      <label>
                        <input type="number" onChange={e => setEditedSubtaskDaysBeforeDueDate(e.target.value)} defaultValue={s.daysBeforeDueDate} />
                      </label>
                    </form>
                  )}
                  {(editIndex === null || editIndex === index) && (id === a.id || id === "") && (
                    <button type="button" className="btn edit-btn-small" style={{ height: "fit-content" }} onClick={() => handleEdit(a, index)}>
                      edit
                    </button>
                  )}
                </span>
              ))}
            {isAdding && id === a.id && (
              <>
                <label>
                  <input required type="text" style={{ marginTop: ".8em" }} placeholder="add new subtask" onChange={e => setNewSubtask(e.target.value)} />
                </label>
                <label>
                  <Select options={peopleOptions} onChange={option => setNewSubtaskAssignTo(option.value)} />
                </label>
                <label>
                  <input type="number" placeholder="days before due date" onChange={e => setNewSubtaskDaysBeforeDueDate(e.target.value)} />
                </label>
              </>
            )}
            {id === a.id && (
              <button type="button" className="btn edit-btn-small" style={{ marginTop: ".8em" }} onClick={() => handleUpdate(a)}>
                save
              </button>
            )}
          </div>
        ))}

    </>
  )
}
