import { useEffect, useState } from "react"
import { db } from "../../firebase/config"
import { collection, getDocs } from "firebase/firestore"
import TaskList from "../../components/TaskList"
import { useCollection } from "../../hooks/useCollection"
import Schedule from "../schedule/Schedule"
import { useAuthContext } from "../../hooks/useAuthContext"
import CardLibraryList from "../../components/CardLibraryList"
import axios from "axios"

// styles
//import "./Dashboard.css"

export default function Dashboard() {
  const { user } = useAuthContext()
  const d = new Date()
  d.setDate(d.getDate() - 1)
  const _q = ["dueDate", ">=", d]
  const { documents: tasks } = useCollection("tasks", _q, ["dueDate", "desc"])

  console.log("uid", user.uid)
  const { documents: cardLibrary } = useCollection("cardLibrary", ["uid", "==", user.uid])
  console.log("cdlib", cardLibrary)

  const getData = () => {
    const url = `https://queue-times.com/parks/64/queue_times.json`
    axios.get(url).then(response => {
      //setData(response.data)
      console.log("QUEUE TEST RESPONSE: ", response.data)
    })
  }

  return (
    <div>
      <p>Dashboard</p>
      <button onClick={getData}>Get Data</button>
      <div style={{ marginBottom: "1em" }}>
        <Schedule limit={true} />
      </div>
      <div style={{ marginBottom: "1em" }}>{tasks && <TaskList tasks={tasks} limit={true} />}</div>
      <div style={{ marginBottom: "1em" }}>{cardLibrary && <CardLibraryList cardLibrary={cardLibrary} limit={true} />}</div>
    </div>
  )
}
