import { useCollection } from "../../hooks/useCollection"
import { useParams, useLocation } from "react-router-dom"
import { useState } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { useNavigate } from "react-router-dom"
import { collection, deleteDoc, addDoc, updateDoc } from "firebase/firestore"
import Select from "react-select"
import { statusOptions } from "../../Constants"

// import styles
import "./Task.css"

export default function Task() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { documents: subtasks } = useCollection("subtasks", ["taskId", "==", id])
  const { documents: people } = useCollection("people")

  // sort by rank
  if (subtasks) {
    subtasks.sort((a, b) => a.rank - b.rank)
  }

  let peopleOptions = null
  if (people != null) {
    peopleOptions = people.map(p => {
      return { value: p.name, label: p.name }
    })
  }

  const { documents: autoGenerateTasks } = useCollection("autoGenerateTask")
  let taskOptions = null
  if (autoGenerateTasks != null) {
    taskOptions = autoGenerateTasks.map(t => {
      return { value: t.taskTitle, label: t.taskTitle }
    })
  }

  // this allows values to be passed with the link for the page title
  const location = useLocation()
  let parent = null
  console.log("location.state", location.state)
  if (location.state == null) {
    console.log("NULL")
    parent = { title: "" }
    navigate("/")
  } else {
    console.log("NOT NULL")
    parent = location.state.parent
  }

  const [subtaskId, setSubtaskId] = useState("")
  const [subtaskTitle, setSubtaskTitle] = useState("")
  const [rank, setRank] = useState("")
  const [subtaskDescription, setSubtaskDescription] = useState("")
  const [subtaskAssignedTo, setSubtaskAssignedTo] = useState("")
  const [subtaskStatus, setSubtaskStatus] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editId, setEditId] = useState("")
  const [autoGenerateOption, setAutoGenerateOption] = useState("")


  const handleGenerateTask = async () => {
    if (autoGenerateOption === "" || dueDate === "") {
      alert("please select an option and a due date")
    } else {
      if (window.confirm("Create predefined " + autoGenerateOption + " subtasks for " + parent.title + "?") === true) {
        let filtered = autoGenerateTasks.find(f => f.taskTitle === autoGenerateOption)
        const ref = collection(db, "subtasks")

        // create a subtask for each
        filtered.subtasks.forEach((s, rank) => {
          saveGeneratedSubtask(ref, s, rank)
        })
        setIsAdding(false)
      }
    }
  }

  const saveGeneratedSubtask = async (ref, s, rank) => {
    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    dateFormat.setDate(dateFormat.getDate() - +s.daysBeforeDueDate)
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      taskId: id,
      title: s.subtaskTitle.trim(),
      rank: +rank,
      dueDate:  Timestamp.fromDate(new Date(dateStr)),
      // description: subtaskDescription.trim(),
      assignedTo: s.assignTo.trim(),
      status: "Open",
    }).finally(console.log("added: ", s))
  }

  const handleAdd = () => {
    setSubtaskId("")
    setSubtaskTitle("")
    setSubtaskDescription("")
    setSubtaskAssignedTo("")
    setSubtaskStatus("")
    setDueDate("")

    if (isAdding) {
      setIsAdding(false)
    } else {
      setIsAdding(true)
    }
  }

  const handleAddSubtask = async e => {
    e.preventDefault()
    const ref = collection(db, "subtasks")

    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      taskId: id,
      title: subtaskTitle.trim(),
      rank: +rank,
      description: subtaskDescription.trim(),
      assignedTo: subtaskAssignedTo.trim(),
      status: subtaskStatus.trim(),
      dueDate: Timestamp.fromDate(new Date(dateStr))
    })

    setIsAdding(false)
  }

  const handleUpdateSubtask = async e => {
    e.preventDefault()

    const docRef = doc(db, "subtasks", subtaskId)

    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    const data = {
      title: subtaskTitle.trim(),
      rank: +rank,
      description: subtaskDescription.trim(),
      assignedTo: subtaskAssignedTo.trim(),
      status: subtaskStatus.trim(),
      dueDate: Timestamp.fromDate(new Date(dateStr))
    }

    updateDoc(docRef, data).then(docRef => {
      console.log("Subtask updated")
    })

    setIsEditing(false)
    setEditId(null)
  }

  const handleDeleteSubtask = async id => {
    const docRef = doc(db, "subtasks", id)

    if (window.confirm("Are you sure you want to delete this task?") === true) {
      await deleteDoc(docRef)
      console.log("delete confirmed")
    } else {
      console.log("nothing was deleted")
    }
  }

  const handleEditSubtask = async subtask => {
    setSubtaskId(subtask.id)
    setSubtaskTitle(subtask.title)
    setRank(subtask.rank)
    setSubtaskDescription(subtask.description)
    setSubtaskStatus(subtask.status)
    setSubtaskAssignedTo(subtask.assignedTo)

    setDueDate(subtask.dueDate ? subtask.dueDate.toDate().toISOString().substr(0, 10) : "")

    setIsEditing(true)
    setEditId(subtask.id)
    setIsAdding(false)
  }

  const handleCancelEditSubtask = e => {
    setIsEditing(false)
    setEditId("")
  }

  return (
    <div>
      <span className="page-title" style={{ marginBottom: ".8em" }}>
        <h4>{parent.title}</h4>
        {autoGenerateOption !== "" && (
          <button className="btn" style={{ marginLeft: "auto" }} onClick={handleGenerateTask}>
            Generate
          </button>
        )}
        <button className="btn" style={autoGenerateOption === "" ? { marginLeft: "auto" } : { marginLeft: "10px" }} onClick={handleAdd}>
          add
        </button>
      </span>

      {isAdding && (
        <>
          <span style={{ marginBottom: ".5em" }}>
            <span>Generate subtasks:</span>
            <Select options={taskOptions} onChange={option => setAutoGenerateOption(option.value)} />
            {autoGenerateOption !== "" && (
              <>
                <span>Due Date:</span>
                <input type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
              </>
            )}
          </span>

          <hr />
          {autoGenerateOption === "" && (
            <>
              <form onSubmit={handleAddSubtask}>
                <label>
                  <span>Subtask Title:</span>
                  <input type="text" required onChange={e => setSubtaskTitle(e.target.value)} value={subtaskTitle} />
                </label>
                <label>
                  <span>Rank:</span>
                  <input type="number" required onChange={e => setRank(e.target.value)} value={rank} />
                </label>
                <label>
                  <span>Subtask Description:</span>
                  <input type="text" onChange={e => setSubtaskDescription(e.target.value)} value={subtaskDescription} />
                </label>
                <label>
                  <span>Subtask Assigned To:</span>
                  <Select options={peopleOptions} onChange={option => setSubtaskAssignedTo(option.value)} />
                </label>
                <label>
                  <span>Subtask Status:</span>
                  <Select options={statusOptions} onChange={option => setSubtaskStatus(option.value)} />
                </label>
                <label>
                  <span>Due Date:</span>
                  <input required type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
                </label>
                <button className="btn" disabled={subtaskTitle === ""}>
                  add
                </button>
              </form>
              <hr />
            </>
          )}
        </>
      )}
      {subtasks &&
        subtasks.map(subtask => (
          <div className="subtask-list" key={subtask.id}>
            {isEditing && editId === subtask.id && (
              <form onSubmit={handleUpdateSubtask}>
                <label>
                  <span>Subtask Title:</span>
                  <input type="text" required onChange={e => setSubtaskTitle(e.target.value)} value={subtaskTitle} />
                </label>
                <label>
                  <span>Rank:</span>
                  <input type="number" required onChange={e => setRank(e.target.value)} value={rank} />
                </label>
                <label>
                  <span>Subtask Description:</span>
                  <input type="text" onChange={e => setSubtaskDescription(e.target.value)} value={subtaskDescription} />
                </label>
                <label>
                  <span>Subtask Assigned To:</span>
                  {/* <input type="text" onChange={e => setSubtaskAssignedTo(e.target.value)} value={subtaskAssignedTo} /> */}
                  <Select options={peopleOptions} onChange={option => setSubtaskAssignedTo(option.value)} value={{ value: subtaskAssignedTo, label: subtaskAssignedTo }} />
                </label>
                <label>
                  <span>Subtask Status:</span>
                  <Select options={statusOptions} onChange={option => setSubtaskStatus(option.value)} value={{ value: subtaskStatus, label: subtaskStatus }} />
                </label>
                <label>
                  <span>Due Date:</span>
                  <input required type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
                </label>
                <button className="btn add-button" disabled={subtaskTitle === ""}>
                  update
                </button>
                <button type="button" className="btn add-button" style={{ marginLeft: "6px" }} onClick={() => handleCancelEditSubtask()}>
                  cancel
                </button>
              </form>
            )}
            {editId !== subtask.id && (
              <>
                <span style={{ display: "flex" }}>
                  <p style={{ fontWeight: "600", fontSize: "1.1em" }}>{subtask.title}</p>
                  <span style={{ marginLeft: "auto" }}>
                    <button className="btn delete-subtask-button" onClick={() => handleDeleteSubtask(subtask.id)}>
                      delete
                    </button>
                    {/* {subtask.status !== "Complete" && ( */}
                    <button className="btn delete-subtask-button" onClick={() => handleEditSubtask(subtask)}>
                      edit
                    </button>
                    {/* )} */}
                  </span>
                </span>

                <p style={{ fontWeight: "600" }}>Description: </p>
                {subtask.description}
                <p style={{ fontWeight: "600" }}>Status: </p>
                {subtask.status}
                <p style={{ fontWeight: "600" }}>Assigned To: </p>
                {subtask.assignedTo}
                <p style={{ fontWeight: "600" }}>Due Date: </p>
                {subtask.dueDate && subtask.dueDate.toDate().toDateString()}
              </>
            )}
          </div>
        ))}
    </div>
  )
}