import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, getDocs, addDoc, deleteDoc, orderBy, updateDoc } from "firebase/firestore"
import { useCollection } from "../../hooks/useCollection"

// styles
import "./Schedule.css"

export default function Schedule({ limit }) {
  const d = new Date()
  d.setDate(d.getDate() - 1)
  const _q = limit ? ["date", ">=", d] : null
  const { documents: schedule } = useCollection('schedule', _q, null)

  const [isAdding, setIsAdding] = useState(false)
  const [editId, setEditId] = useState(null)
  const [title, setTitle] = useState("")
  const [judges, setJudges] = useState("")
  const [commentators, setCommentators] = useState("")
  const [date, setDate] = useState("")
  const [sortBy, setSortBy] = useState("Asc")

  if (schedule && sortBy === "Asc") {
    schedule.sort((a, b) => a.date - b.date)
  } else if (schedule && sortBy === "Desc") {
    schedule.sort((a, b) => b.date - a.date)
  }

  if (schedule && limit) schedule.length = 3

  const handleSort = () => {
    if (sortBy === "Asc") {
      setSortBy("Desc")
      //schedule.sort((a, b) => a.date - b.date)
    } else {
      setSortBy("Asc")
      //schedule.sort((a, b) => b.date - a.date)
    }
  }

  const handleAddEvent = () => {
    if (isAdding) {
      setIsAdding(false)
    } else {
      setIsAdding(true)
    }
  }

  const handleSave = async e => {
    e.preventDefault()
    const ref = collection(db, "schedule")

    let dateFormat = new Date(date)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      title: title.trim(),
      judges: judges.trim(),
      commentators: commentators.trim(),
      date: Timestamp.fromDate(new Date(dateStr)),

    }).then(
      setTitle(""),
      setJudges(""),
      setCommentators(""),
      setDate(""),
      setIsAdding(false)
    ).finally(
      console.log("added event successfully"),
      //handleSort()
    )
  }

  const handleEdit = (s) => {
    setTitle(s.title)
    setCommentators(s.commentators)
    setDate(s.date.toDate().toISOString().substr(0, 10))
    setEditId(s.id)
  }

  const handleCancelEdit = e => {
    setEditId(null)
  }

  const handleUpdate = async e => {
    e.preventDefault()
    const docRef = doc(db, "schedule", editId)

    let dateFormat = new Date(date)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    const data = {
      title: title.trim(),
      judges: judges.trim(),
      commentators: commentators.trim(),
      dueDate: Timestamp.fromDate(new Date(dateStr))
    }

    try {
      updateDoc(docRef, data).then(docRef => {
        console.log("Schedule updated")
      })
    } catch (error) {
      console.log("Error updating schedule")
    }

    setEditId(null)
  }

  const handleDelete = async event => {
    if (window.confirm("Delete: " + event.title + "?") === true) {
      const docRef = doc(db, "schedule", event.id)
      await deleteDoc(docRef)
      console.log("delete confirmed")
    } else {
      console.log("nothing was deleted")
    }
  }

  return (
    <>
      <span className="page-title">
        <h4>Schedule</h4>
        <span style={{ marginLeft: "auto" }}>
          {limit ? (<></>) : (<button className="btn edit-button" onClick={handleSort}>Sort</button>)}
          {limit ? (<></>) : (<button className="btn edit-button" onClick={handleAddEvent}>{isAdding && 'cancel'}{!isAdding && 'add'}</button>)}
        </span>
      </span>

      {isAdding && (
        <form onSubmit={handleSave}>
          <label>
            <span>Title:</span>
            <input required type="text" onChange={e => setTitle(e.target.value)} value={title} />
          </label>
          <label>
            <span>Judges:</span>
            <input type="text" onChange={e => setJudges(e.target.value)} value={judges} />
          </label>
          <label>
            <span>Commentators:</span>
            <input type="text" onChange={e => setCommentators(e.target.value)} value={commentators} />
          </label>
          <label>
            <span>Date:</span>
            <input required type="date" onChange={e => setDate(e.target.value)} value={date} />
          </label>
          <button className="btn">save</button>
        </form>
      )}

      <div className="event-list">
        {schedule &&
          schedule.map(s => (
            <div key={s.id}>
              {editId === s.id && (
                <>
                  <form onSubmit={handleUpdate}>
                    <label>
                      <span>Title:</span>
                      <input required type="text" onChange={e => setTitle(e.target.value)} value={title}></input>
                    </label>
                    <label>
                      <span>Judges:</span>
                      <input type="text" onChange={e => setJudges(e.target.value)} value={judges}></input>
                    </label>
                    <label>
                      <span>Commentators:</span>
                      <input type="text" onChange={e => setCommentators(e.target.value)} value={commentators}></input>
                    </label>
                    <label>
                      <span>Date:</span>
                      <input required type="date" onChange={e => setDate(e.target.value)} value={date} />
                    </label>
                    <button type="button" className="btn" onClick={() => handleCancelEdit()}>
                      cancel
                    </button>
                    <button className="btn" style={{ marginLeft: "6px"}}>save</button>
                  </form>
                </>
              )}
              {editId !== s.id && (
                <>
                  <span style={{ display: "flex" }}>
                    <h4>{s.title}</h4>
                    {limit ? (<></>) : (<button className="btn edit-btn-small" style={{ marginLeft: "auto" }} onClick={() => handleEdit(s)}>edit</button>)}
                    {limit ? (<></>) : (<button className="btn delete-button" onClick={() => handleDelete(s)}>delete</button>)}
                  </span>
                  <p style={{display: "flex" }}>Judges: {s.judges}</p>
                  <p style={{display: "flex"}}>Commentators: {s.commentators}</p>
                  <p className="warning-text">{s.date.toDate().toDateString()}</p>
                </>
              )}

            </div>
          ))}
      </div>
    </>
  )
}
