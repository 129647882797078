import { Link } from "react-router-dom"
import { useState } from "react"
import { db, Timestamp } from "../firebase/config"
import { collection, doc, deleteDoc, updateDoc, addDoc, query, where, getDocs } from "firebase/firestore"
import Select from "react-select"
import { statusOptions } from "../Constants"
import { useCollection } from "../hooks/useCollection"

// styles
import "./TaskList.css"

export default function TaskList({ tasks, limit }) {
  const [editTask, setEditTask] = useState(false)

  const [isAddingTask, setIsAddingTask] = useState(false)

  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [status, setStatus] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [message, setMessage] = useState("")

  tasks.sort((a, b) => a.dueDate - b.dueDate)

  let completeTasks = tasks.filter(f => f.status === "Complete")
  console.log("completeTasks",completeTasks)

  const { documents: autoGenerateTasks } = useCollection('autoGenerateTask')
  let taskOptions = null
  if (autoGenerateTasks != null) {
    taskOptions = autoGenerateTasks.map(t => {
      return { value: t.taskTitle, label: t.taskTitle }
    })
    console.log("taskOptions", taskOptions)
  }

  const handleCancelEditTask = e => {
    setEditTask(false)
    setMessage("")
  }

  const handleCancelAddTask = e => {
    setIsAddingTask(false)
  }

  const handleEditTask = task => {
    if (editTask) {
      setEditTask(false)
    } else {
      setId(task.id)
      setTitle(task.title)
      setDescription(task.description)
      setStatus(task.status)
      setDueDate(task.dueDate.toDate().toISOString().substr(0, 10))
      setEditTask(true)
    }
  }

  const handleMoveForward = task => {
    let statusUpdate = ""
    if (task.status === "Open") {
      statusUpdate = "In Progress"
    } else if (task.status === "In Progress") {
      statusUpdate = "Complete"
    } else if (task.status === "On Hold") {
      statusUpdate = "Open"
    }

    if (window.confirm('Move status forward to "' + statusUpdate + '"?') === true) {
    const docRef = doc(db, "tasks", task.id)

    const data = {
      status: statusUpdate.trim()
    }

    updateDoc(docRef, data).then(docRef => {
      console.log("Task status moved forward")
    })
    } else {
      console.log("move forward canceled")
    }
  }

  const handleSave = async e => {
    e.preventDefault()

    //TODO
    const docRef = doc(db, "tasks", id)

    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    const data = {
      title: title.trim(),
      description: description.trim(),
      status: status.trim(),
      dueDate: Timestamp.fromDate(new Date(dateStr))
    }

    try {
      updateDoc(docRef, data).then(docRef => {
        setEditTask(false)
        setMessage("")
      })
    } catch (error) {
      setMessage("Error updating task")
    }
  }

  const handleAddTask = () => {
    console.log("Add Task")
    setEditTask(false)
    setTitle("")
    setDescription("")
    setStatus("")
    setDueDate("")
    if (isAddingTask) {
      setIsAddingTask(false)
    } else {
      setIsAddingTask(true)
    }
  }

  const handleSubmitTask = async e => {
    e.preventDefault()
    const ref = collection(db, "tasks")

    let dateFormat = new Date(dueDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      title: title.trim(),
      description: description.trim(),
      status: status.trim(),
      dueDate: Timestamp.fromDate(new Date(dateStr))
    })

    setIsAddingTask(false)
  }

  const handleDeleteTask = async (id) => {
    if (window.confirm("Are you sure you want to delete this task and its subtasks?") === true) {
      const q = query(collection(db, "subtasks"), where("taskId", "==", id));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((d) => {
        // doc.data() is never undefined for query doc snapshots
        const docRef = doc(db, "subtasks", d.id)
        deleteDoc(docRef)
      })

      const deleteDocRef = doc(db, "tasks", id)
      await deleteDoc(deleteDocRef)
      setEditTask(false)
    } else {
      console.log("nothing was deleted")
    }
  }

  return (
    <>
      <span className="page-title">
        <h4>Tasks</h4>
        <span style={{ display: "flex", marginLeft: "auto" }}>
          {limit ? (<></>) : (<button className="btn add-button" onClick={handleAddTask}>Create Task</button>)}
        </span>
      </span>
      {isAddingTask && (
        <form onSubmit={handleSubmitTask}>
          <p>add mode</p>
          <label>
            <span>Task Title:</span>
            <input required type="text" onChange={e => setTitle(e.target.value)} value={title} />
          </label>
          <label>
            <span>Description:</span>
            <input type="text" onChange={e => setDescription(e.target.value)} value={description} />
          </label>
          <label>
            <span>Status:</span>
            <Select options={statusOptions} onChange={option => setStatus(option.value)} />
            {/* <input required type="text" onChange={e => setStatus(e.target.value)} value={status} /> */}
          </label>
          <label>
            <span>Due Date:</span>
            <input required type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
          </label>
          <button type="button" className="btn" onClick={() => handleCancelAddTask()}>
            cancel
          </button>
          <button className="btn" style={{ marginLeft: "6px" }}>save</button>
        </form>
      )}
      {editTask && (
        <form onSubmit={handleSave}>
          <p>edit mode</p>
          <label>
            <span>Task Title:</span>
            <input required type="text" onChange={e => setTitle(e.target.value)} value={title} />
          </label>
          <label>
            <span>Description:</span>
            <input type="text" onChange={e => setDescription(e.target.value)} value={description} />
          </label>
          <label>
            <span>Status:</span>
            <Select options={statusOptions} onChange={option => setStatus(option.value)} value={{ value: status, label: status }} />
          </label>
          <label>
            <span>Due Date:</span>
            <input required type="date" onChange={e => setDueDate(e.target.value)} value={dueDate} />
          </label>
          <span style={{display: "flex"}}> 
          <button type="button" className="btn" onClick={() => handleCancelEditTask()}>
            cancel
          </button>
          <button className="btn" style={{ marginLeft: "6px" }}>save</button>
          <button type="button" className="btn delete-btn" style={{marginLeft: "auto"}} onClick={() => handleDeleteTask(id)}>delete</button>
          </span>
          {message && <p>{message}</p>}
        </form>
      )}
      <div className="task-list">
        {tasks.length === 0 && <p>no tasks yet!</p>}
        {!editTask &&
          tasks.map(task => (
            <div key={task.id}>
              <span>
                {/* <h4>{task.title}</h4> */}
                <Link to={`/task/${task.id}`} state={{ parent: task }}>
                  {task.title}
                </Link>
              </span>
              <p>{task.description}</p>
              <p>{task.status}</p>
              <p className="warning-text">Due by: {task.dueDate.toDate().toDateString()}</p>
              <span>
                <button className="btn" onClick={() => handleEditTask(task)}>
                  Edit task
                </button>
                {task.status !== "Complete" && (
                  <button className="btn" style={{ marginLeft: "6px" }} onClick={() => handleMoveForward(task)}>
                    move forward
                  </button>
                )}
              </span>
            </div>
          ))}
      </div>
    </>
  )
}
