import { useEffect, useState } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore"
import { useCollection } from "../../hooks/useCollection"
import Select from "react-select"



export default function StipendAdmin() {
    const [selectedQuarter, setSelectedQuarter] = useState(null)
    const [stipends, setStipends] = useState(null)


    const { documents: quarters } = useCollection("quarters")

    if (quarters !== null) {
        quarters.sort((a, b) => (a.quarterName > b.quarterName ? 1 : b.quarterName > a.quarterName ? -1 : 0))
    }
    let quarterOptions = null
    if (quarters != null) {
        quarterOptions = quarters.map(q => {
            return { value: q.quarterName, label: q.quarterName + " - " + "(" + q.dateRange + ")" }
        })
    }

    let report = []

    useEffect(() => {
        let ref = collection(db, "stipends")
    
        if (selectedQuarter !== "") {
          ref = query(ref, where(...["quarter", "==", selectedQuarter]))
        }
    
        const unsub = onSnapshot(ref, snapshot => {
          let results = []
          snapshot.docs.forEach(doc => {
            results.push({ id: doc.id, ...doc.data() })
          })
          

          setStipends(results)
        })
    
        return () => unsub()
      }, [selectedQuarter])

 if (stipends && stipends.length > 0) {
    console.log("stipends!", stipends)

    let regionalStipendTotal = stipends ? stipends.map(item => item.total).reduce((prev, next) => prev + next) : []
    console.log("regionalStipendTotal:", regionalStipendTotal)

    let dayTwoBonusTotal = stipends && stipends.map(item => item.dayTwoCount ? +item.dayTwoCount : +0).reduce((prev, next) => prev + next);
    console.log("dayTwoBonusTotal:", dayTwoBonusTotal)

    let socialMediaBonusTotal = stipends && stipends.map(item => item.socialMedia ? +item.socialMedia : +0).reduce((prev, next) => prev + next);
    console.log("socialMediaBonusTotal:", socialMediaBonusTotal)

    let participationBonusTotal = stipends && stipends.map(item => item.participation ? +item.participation : +0).reduce((prev, next) => prev + next);
    console.log("participationBonusTotal:", participationBonusTotal)

    let attendNrgBonusTotal = stipends && stipends.map(item => item.attendNrg ? +item.attendNrg : +0).reduce((prev, next) => prev + next);
    console.log("attendNrgBonusTotal:", attendNrgBonusTotal)

    let entranceStipend = 25
    let dayTwoPercent = .15
    let socialMediaPercent = .2
    let attendNrgPercent = .3
    let participationPercent = .35

    let entranceStipendAmount = 0
    let dayTwoAmount = 0
    let socialMediaAmount = 0
    let attendNrgAmount = 0
    let participationAmount = 0

    let quarterBudget = +1000

    // full budget - unlimited regional, spe, and ic stipends
    quarterBudget = quarterBudget - regionalStipendTotal

    dayTwoAmount = quarterBudget * dayTwoPercent
    console.log("dayTwoAmount", dayTwoAmount)

    socialMediaAmount = quarterBudget * socialMediaPercent
    console.log("socialMediaAmount", socialMediaAmount)

    attendNrgAmount = quarterBudget * attendNrgPercent
    console.log("attendNrgAmount", attendNrgAmount)

    participationAmount = quarterBudget * participationPercent
    console.log("participationAmount", participationAmount)

    // let USDollar = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    // });

    stipends && stipends.forEach(p => {
        let personReport = {
            name: p.name,
            entranceStipend: entranceStipend * p.eventsArray.length,
            dayTwoBonus: dayTwoAmount / dayTwoBonusTotal * p.dayTwoCount,
            socialMediaBonus: socialMediaAmount / socialMediaBonusTotal * p.socialMedia,
            attendNrgBonus: attendNrgAmount / attendNrgBonusTotal * p.attendNrg,
            participationBonus: participationAmount / participationBonusTotal * p.participation,
        }
        personReport.total =
            (personReport.entranceStipend ? personReport.entranceStipend : 0) +
            (personReport.dayTwoBonus ? personReport.dayTwoBonus : 0) +
            (personReport.socialMediaBonus ? personReport.socialMediaBonus : 0) +
            (personReport.attendNrgBonus ? personReport.attendNrgBonus : 0) +
            (personReport.participationBonus ? personReport.participationBonus : 0)

        report.push(personReport)

    });

    report.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
 }
    console.table(report)

    return (
        <>
            <span style={{ display: "flex", marginTop: "1em" }}>
                <p>Stipend Admin</p>
                <span style={{ marginLeft: "auto" }}>
                    {/* <button className="btn" onClick={() => showReport()}>
                        show report
                    </button> */}
                </span>
            </span>
            <label>
                <span>Filter by Quarter:</span>
                <Select options={quarterOptions} onChange={option => setSelectedQuarter(option.value)} />
            </label>

            <div>
                <table className="styled-table" style={{ tableLayout: "fixed", width: "100%", wordWrap: "break-word", marginRight: "auto", marginLeft: "auto" }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Total</th>
                            <th>Day 2</th>
                            <th>Social Media</th>
                            <th>Participation</th>
                            <th>Attend NRG</th>
                        </tr>
                    </thead>
                    <tbody>
                        {report && report.map((item, i) => (
                            <tr key={i}>
                                <td>{item.name}</td>
                                <td>{item.total ? item.total.toFixed(2) : ""}</td>
                                <td>{item.dayTwoBonus ? item.dayTwoBonus.toFixed(2) : ""}</td>
                                <td>{item.socialMediaBonus ? item.socialMediaBonus.toFixed(2) : "" }</td>
                                <td>{item.participationBonus ? item.participationBonus.toFixed(2) : ""}</td>
                                <td>{item.attendNrgBonus ? item.attendNrgBonus.toFixed(2) : ""}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}