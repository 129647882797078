import { initializeApp } from "firebase/app"
//import { getDoc, doc, getFirestore, Timestamp } from "firebase/firestore"
import { getDoc, doc, getFirestore, Timestamp, updateDoc, arrayRemove } from "firebase/firestore"
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBxeGlv9--lCwNKW0QPL-tiO5snawvqu5c",
  authDomain: "taskmanager-c92df.firebaseapp.com",
  projectId: "taskmanager-c92df",
  storageBucket: "taskmanager-c92df.appspot.com",
  messagingSenderId: "513534942334",
  appId: "1:513534942334:web:6ea09393275ad542164c2f"
}

// init firebase
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init firebase auth
const auth = getAuth()

export { db, doc, getDoc, updateDoc, Timestamp, arrayRemove, auth }
