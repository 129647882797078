import { useState } from "react"
import { db, doc } from "../../firebase/config"
import { collection, addDoc, updateDoc } from "firebase/firestore"

export default function QuarterAdmin({ quarters }) {
    //const [quarters, setQuarters] = useState("")
    const [isAddingQuarter, setIsAddingQuarter] = useState(false)
    const [editQuarterId, setEditQuarterId] = useState(null)
    const [quarterName, setQuarterName] = useState("")
    const [quarterDateRange, setQuarterDateRange] = useState("")
    const [eventsArray, setEventsArray] = useState([])
    const [quarterEventToAdd, setQuarterEventToAdd] = useState("")

    console.log("quarters", quarters)
    if (quarters !== null) {
        quarters.sort((a, b) => (a.quarterName > b.quarterName) ? 1 : ((b.quarterName > a.quarterName) ? -1 : 0))
    }

    const handleQuarterAdd = () => {
        console.log("handleQuarterAdd")
        if (isAddingQuarter) {
            setIsAddingQuarter(false)
        } else {
            setIsAddingQuarter(true)
        }
    }

    const handleQuarterAddSubmit = async e => {
        e.preventDefault()

        const ref = collection(db, "quarters")
        await addDoc(ref, {
            quarterName: quarterName.trim(),
            dateRange: quarterDateRange.trim()
        })

        setIsAddingQuarter(false)
    }

    const handleCancel = () => {
        setEditQuarterId(null)
    }

    const handleQuarterEdit = q => {
        setQuarterName(q.quarterName)
        setQuarterDateRange(q.dateRange)
        setEventsArray(q.events)
        setEditQuarterId(q.id)
    }

    const handleQuarterEditSubmit = async e => {
        e.preventDefault()

        const docRef = doc(db, "quarters", editQuarterId)

        const data = {
            quarterName: quarterName.trim(),
            dateRange: quarterDateRange.trim(),
            events: eventsArray
        }

        updateDoc(docRef, data).then(docRef => {
            console.log("quarter updated")
        })

        setEditQuarterId(null)
    }

    const handleQuarterEventDelete = async (e, quarter) => {
        if (window.confirm("Delete " + e + " from " + quarter.quarterName + "?") === true) {
            let filteredArray = eventsArray.filter(f => f !== e)

            const data = {
                events: filteredArray
            }

            const docRef = doc(db, "quarters", editQuarterId)

            try {
                updateDoc(docRef, data).then(docRef => {
                    console.log("Event removed")
                })
            } catch (error) {
                console.log("error:", error)
            }
            setEditQuarterId("")
        } else {
            console.log("Nothing was deleted")
        }
    }

    const handleQuarterEventAdd = async e => {
        let newEventsArray = eventsArray ? [...eventsArray] : new Array()
        newEventsArray.push(quarterEventToAdd)

        // try saving when clicking add
        const docRef = doc(db, "quarters", editQuarterId)

        const data = {
            events: newEventsArray
        }

        updateDoc(docRef, data).then(docRef => {
            console.log("quarter updated")
        })


        setEditQuarterId(null)
        setQuarterEventToAdd("")
    }

    const handleDownRank = (a, index) => {
        let arr = a.events

        const fromIndex = index
        const toIndex = index + 1

        const element = arr.splice(fromIndex, 1)[0]

        arr.splice(toIndex, 0, element)
        const data = {
            events: arr
        }

        const docRef = doc(db, "quarters", editQuarterId)

        try {
            updateDoc(docRef, data).then(docRef => {
                console.log("Updated rank")
            })
        } catch (error) {
            console.log("error:", error)
        }
        //setEditIndex(toIndex)
    }

    const handleUpRank = (a, index) => {
        let arr = a.events

        const fromIndex = index
        const toIndex = index - 1

        const element = arr.splice(fromIndex, 1)[0]

        arr.splice(toIndex, 0, element)

        const data = {
            events: arr
        }

        const docRef = doc(db, "quarters", editQuarterId)

        try {
            updateDoc(docRef, data).then(docRef => {
                console.log("Updated rank")
            })
        } catch (error) {
            console.log("error:", error)
        }
        // setEditIndex(toIndex)
    }

    // Drag test
    // const dragItem = useRef()
    // const dragOverItem = useRef()
    // // const [list, setList] = useState(['Item 1','Item 2','Item 3','Item 4','Item 5','Item 6']);

    // const dragStart = (e, position) => {
    //   dragItem.current = position
    //   console.log(e.target.innerHTML, position)
    // }

    // const dragEnter = (e, position) => {
    //   dragOverItem.current = position
    //   console.log(e.target.innerHTML, position)
    // }

    // const drop = e => {
    //   const copyListItems = [...eventsArray]
    //   const dragItemContent = copyListItems[dragItem.current]
    //   copyListItems.splice(dragItem.current, 1)
    //   copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    //   dragItem.current = null
    //   dragOverItem.current = null
    //   setEventsArray(copyListItems)
    // }

    return (
        <>
            <span style={{ display: "flex", marginTop: "1em" }}>
                <p>Quarters</p>
                <span style={{ marginLeft: "auto" }}>
                    <button className="btn" onClick={() => { window.open("https://www.pokemon.com/us/play-pokemon/pokemon-events/pokemon-tournaments/regional-championships/") }}>Regional list</button>
                    <button className="btn" style={{ marginLeft: "6px" }} onClick={() => handleQuarterAdd()}>
                        add
                    </button>
                </span>
            </span>
            {isAddingQuarter && (
                <form onSubmit={handleQuarterAddSubmit}>
                    <label>
                        <span>Quarter Name:</span>
                        <input required type="text" onChange={e => setQuarterName(e.target.value)} placeholder="YYYY Q#" />
                    </label>
                    <label>
                        <span>Date Range</span>
                        <input required type="text " onChange={e => setQuarterDateRange(e.target.value)} />
                    </label>
                    <button className="btn">save</button>
                </form>
            )}

            {quarters &&
                quarters.map(quarter => (
                    <div className="subtask-list" key={quarter.id}>
                        {editQuarterId === quarter.id && (
                            <form onSubmit={handleQuarterEditSubmit}>
                                <label>
                                    <span>Quarter Name:</span>
                                    <p>{quarterName}</p>
                                    {/* <input readOnly type="text" onChange={e => setQuarterName(e.target.value)} placeholder="YYYY Q#" value={quarterName} /> */}
                                </label>
                                <label>
                                    <span>Date Range:</span>
                                    <input required type="text " onChange={e => setQuarterDateRange(e.target.value)} value={quarterDateRange} />
                                </label>
                                <span style={{ display: "flex" }}>Events:</span>
                                {quarter.events &&
                                    quarter.events.map((qe, index) => (
                                        <span key={qe} style={{ display: "flex", width: "60%", marginTop: ".5em", marginLeft: "auto", marginRight: "auto"}}>
                                        {/* <span key={qe} style={{ display: "flex",  marginTop: ".5em", marginLeft: "auto", marginRight: "auto"}}> */}
                                            <p>
                                                {qe}
                                            </p>
                                            {/* <p style={{ cursor: "grab" }} onDragStart={e => dragStart(e, index)} onDragEnter={e => dragEnter(e, index)} onDragEnd={drop} draggable>
                                                {qe}
                                            </p> */}
                                            <button type="button" className="btn delete-btn-small" style={{ marginLeft: "6px", height: "fit-content" }} onClick={() => handleQuarterEventDelete(qe, quarter)}>
                                                X
                                            </button>
                                            <button type="button" className="btn edit-button" style={{marginLeft: "auto"}} onClick={() => handleUpRank(quarter, index)}>
                                                {'\u2191'}
                                            </button>
                                            <button type="button" className="btn edit-button" style={{ marginRight: "" }} onClick={() => handleDownRank(quarter, index)}>
                                                {'\u2193'}
                                            </button>

                                        </span>
                                    ))}
                                <span style={{ display: "flex", width: "60%", marginTop: ".5em", marginLeft: "auto", marginRight: "auto", marginBottom: ".8em" }}>
                                    <input type="text" onChange={e => setQuarterEventToAdd(e.target.value)} value={quarterEventToAdd} />
                                    <button type="button" className="btn edit-button" onClick={() => handleQuarterEventAdd()}>
                                        Add
                                    </button>
                                </span>
                                <span style={{ marginTop: "1em" }}>
                                <button type="button" className="btn" onClick={() => handleCancel()}>cancel</button>
                                <button className="btn" style={{ marginLeft: "6px" }}>
                                    save
                                </button>
                                </span>
                            </form>
                        )}

                        {editQuarterId !== quarter.id && (
                            <div>
                                <span style={{ display: "flex" }}>
                                    <strong>{quarter.quarterName}</strong><hr /><p>{quarter.dateRange}</p>
                                    <button className="btn edit-btn-small" onClick={() => handleQuarterEdit(quarter)}>
                                        edit
                                    </button>
                                </span>

                                <hr />
                                {quarter.events && quarter.events.map(e => <p key={e}>{e}</p>)}

                            </div>
                        )}
                    </div>
                ))}
        </>
    )
}