// import { NavLink } from "react-router-dom"
// // styles & images
// import "./Navbar.css"
// import Logo from "../assets/logo-event-available.svg"

// export default function Navbar() {
//   return (
//     <div className="navbar">
//       <ul>
//         <li className="logo">
//           <NavLink to="/">
//             <img src={Logo} alt="logo" />
//             <span>Task Manager</span>
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   )
// }

import { useState } from "react"
import React from "react"
import { Link, NavLink } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"
import { useMediaQuery } from "../hooks/useMediaQuery"

import "./Navbar.css"
import Logo from "../assets/logo-event-available.svg"

export default function Navbar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  let isPageWide = useMediaQuery("(min-width: 800px)")
  console.log("isPageWide:", isPageWide)

  const [showmobile, setShowmobile] = useState(false)
  const showmobileMenu = () => {
    setShowmobile(!showmobile)
  }

  const handleLogout = () => {
    logout()
    setShowmobile(!showmobile)
  }

  return (
    <>
      <nav className="navigationBar">
        <span style={{ justifyContent: "left" }}>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </span>

        <div className="mobileNavButton" onClick={showmobileMenu}>
          <span className="mobileNavButtonLines" />
          <span className="mobileNavButtonLines" />
          <span className="mobileNavButtonLines" />
        </div>

        {/* <ul className="menuLinks">
          <li>
            <Link to="/cardlibrary">Card Library</Link>
          </li>
          <li>
            <Link to="/schedule">Schedule</Link>
          </li>
        </ul> */}
      </nav>
      <div>
        {showmobile && (
          <div>
            <ul className="mobileMenuLinks">
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/tasks">
                  Tasks
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/metaanalysis">
                  Meta Analysis
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/cardlibrary">
                  Card Library
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/schedule">
                  Schedule
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/stipends">
                  Stipends
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/benefits">
                  Benefits
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/singles">
                  Singles Showcase
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/admin">
                  Admin
                </NavLink>
              </li>
              {/* <li>
                <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={showmobileMenu} to="/timer">
                  Timer
                </NavLink>
              </li> */}
              {!user && (
                <li>
                  <Link onClick={showmobileMenu} to="/login">
                    Login
                  </Link>
                </li>
              )}
              {user && <li onClick={handleLogout}>Logout</li>}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}
