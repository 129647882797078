import { Link } from "react-router-dom"
import { db, doc, Timestamp } from "../firebase/config"
import { collection, deleteDoc, addDoc } from "firebase/firestore"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useCollection } from "../hooks/useCollection"
import Select from "react-select"
import { useAuthContext } from "../hooks/useAuthContext"

// styles
import "./CardLibraryList.css"


export default function CardLibraryList({ cardLibrary, limit }) {
  const { user } = useAuthContext()
  const [sortBy, setSortBy] = useState("")
  const [cardName, setCardName] = useState("")
  const [lender, setLender] = useState("")
  const [borrower, setBorrower] = useState("")
  const [quantity, setQuantity] = useState("")
  const [date, setDate] = useState("")
  const [isAdding, setIsAdding] = useState(false)

  cardLibrary.sort((a, b) => a.date - b.date)

  const { documents: people } = useCollection("people")
  let peopleOptions = null
  if (people != null) {
    peopleOptions = people.map(p => {
      return { value: p.name, label: p.name }
    })
  }

  const handleLenderSort = () => {
    if (sortBy === "lenderAsc") {
      console.log("sort by lender desc")
      setSortBy("lenderDesc")
      cardLibrary.sort((a, b) => b.lender.localeCompare(a.lender))
    } else {
      console.log("sort by lender asc")
      setSortBy("lenderAsc")
      cardLibrary.sort((a, b) => a.lender.localeCompare(b.lender))
    }
  }

  const handleBorrowerSort = () => {
    if (sortBy === "borrowerAsc") {
      console.log("sort by desc")
      setSortBy("borrowerDesc")
      cardLibrary.sort((a, b) => b.borrower.localeCompare(a.borrower))
    } else {
      console.log("sort by asc")
      setSortBy("borrowerAsc")
      cardLibrary.sort((a, b) => a.borrower.localeCompare(b.borrower))
    }
  }

  const handleCardNameSort = () => {
    if (sortBy === "cardNameAsc") {
      setSortBy("cardNameDesc")
      cardLibrary.sort((a, b) => b.cardName.localeCompare(a.cardName))
    } else {
      setSortBy("cardNameAsc")
      cardLibrary.sort((a, b) => a.cardName.localeCompare(b.cardName))
    }
  }

  const handleAdd = () => {
    console.log("Add")
    if (isAdding) {
      setIsAdding(false)
    } else {
      setIsAdding(true)
    }
  }

  const handleDelete = async (id) => {
    console.log("card id: ", id)

    console.log("handleDelete:", id)

    if (window.confirm("Are you sure you want to delete this entry?") === true) {
      const docRef = doc(db, "cardLibrary", id)
      await deleteDoc(docRef)
      //navigate("/")
      console.log("delete confirmed")
    } else {
      console.log("nothing was deleted")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const ref = collection(db, "cardLibrary")

    let dateFormat = new Date(date)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    await addDoc(ref, {
      uid: user.uid,
      cardName: cardName.trim(),
      lender: lender.trim(),
      borrower: borrower.trim(),
      quantity: quantity,
      date: Timestamp.fromDate(new Date(dateStr))

    }).then(
      setCardName(""),
      setLender(""),
      setBorrower(""),
      setQuantity(""),
      setDate("")
    ).finally(console.log("add successful"))

    setIsAdding(false)
  }

  const handleToday = (e) => {
    e.preventDefault()
    //let today = new Date().toLocaleDateString()

    let today = new Date().toISOString().substr(0, 10)
    //today.toDate().toISOString().substr(0, 10)
    console.log("today:", today)
    setDate(today)
  }

  return (
    <>
      <span className="page-title">
        <h4>Card Library</h4>
        <span style={{ marginLeft: "auto" }}>
          {limit ? (<></>) : (<button className="btn edit-button" onClick={handleLenderSort}>Sort by lender</button>)}
          {limit ? (<></>) : (<button className="btn edit-button" onClick={handleCardNameSort}>Sort by card</button>)}
          <button className="btn edit-button" onClick={handleBorrowerSort}>Sort by borrower</button>
          <button className="btn edit-button" onClick={handleAdd}>add</button>
        </span>
      </span>
      {isAdding && (
        <form onSubmit={handleSubmit}>
          <label>
            <span>Card Name:</span>
            <input required type="text" onChange={e => setCardName(e.target.value)} value={cardName} />
          </label>
          <label>
            <span>Lender:</span>
            {/* <input required type="text" onChange={e => setLender(e.target.value)} value={lender} /> */}
            <Select options={peopleOptions} onChange={option => setLender(option.value)} />
          </label>
          <label>
            <span>Borrower:</span>
            <input required type="text" onChange={e => setBorrower(e.target.value)} value={borrower} />
          </label>
          <label>
            <span>Qty:</span>
            <input required type="number" onChange={e => setQuantity(e.target.value)} value={quantity} />
          </label>
          <label>
            <span>Date:</span>
            <span style={{ display: "flex" }}>
              {/* <input required type="text" onChange={e => setDate(e.target.value)} value={date} /> */}
              <input required type="date" onChange={e => setDate(e.target.value)} value={date} />
              <button className="btn edit-button" onClick={handleToday}>Today</button>
            </span>
          </label>
          <button className="btn">Save</button>
        </form>
      )}
      <div className="card-library-list">
        {cardLibrary.length === 0 && <p>no cards yet!</p>}
        {cardLibrary.map(card => (
          <div key={card.id}>
            <span style={{ display: "flex" }}><h4>{card.cardName}</h4>{user.uid === card.uid ? (<button className="btn delete-button" style={{marginLeft: "auto"}} onClick={() => handleDelete(card.id)}>delete</button>) : (<></>) }</span>
            <p>Lender: {card.lender}</p>
            <p>Borrower: {card.borrower}</p>
            <p>Qty: {card.quantity}</p>
            <p>Date: {card.date.toDate().toDateString()}</p>
          </div>
        ))}
      </div>
    </>
  )
}
