import { useEffect, useState } from "react"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, query, where, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore"
import Select from "react-select"
import { useCollection } from "../../hooks/useCollection"
import { yesNoOptions } from "../../Constants"

export default function Stipends() {
  const [selectedQuarter, setSelectedQuarter] = useState(null)
  const [stipends, setStipends] = useState(null)

  const [isAdding, setIsAdding] = useState(false)
  const [editId, setEditId] = useState("")

  const [eventsArray, setEventsArray] = useState(null)

  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [event, setEvent] = useState("")
  const [quarter, setQuarter] = useState("")
  const [stipend, setStipend] = useState(null)
  const [dayTwo, setDayTwo] = useState("")

  const [dayTwoCount, setDayTwoCount] = useState(0)
  const [participation, setParticipation] = useState(0)
  const [socialMedia, setSocialMedia] = useState(0)
  const [attendNrg, setAttendNrg] = useState(0)

  const { documents: people } = useCollection("people")
  const { documents: quarters } = useCollection("quarters")

  if (quarters !== null) {
    quarters.sort((a, b) => (a.quarterName > b.quarterName ? 1 : b.quarterName > a.quarterName ? -1 : 0))
  }

  let quarterOptions = null
  if (quarters != null) {
    quarterOptions = quarters.map(q => {
      return { value: q.quarterName, label: q.quarterName + " - " + "(" + q.dateRange + ")" }
    })
  }

  let eventOptions = null
  if (selectedQuarter) {
    let filter = quarters ? quarters.filter(f => f.quarterName === selectedQuarter) : null
    if (filter[0].events) {
      eventOptions = filter[0].events.map(e => {
        return { value: e.toString(), label: e.toString() }
      })
    }
  }

  useEffect(() => {
    setIsAdding(false)
    setEditId("")
    let ref = collection(db, "stipends")

    if (selectedQuarter !== "") {
      ref = query(ref, where(...["quarter", "==", selectedQuarter]))
    }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })
      // sort
      results.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      setStipends(results)
    })

    return () => unsub()
  }, [selectedQuarter])

  const handleAdd = async e => {
    setEditId("")

    if (isAdding) {
      setIsAdding(false)
    } else {
      setId(e.id)
      setName(e.name)
      setQuarter(e.quarter)
      setEventsArray(e.eventsArray)
      setStipend(25)
      setDayTwo("No")
      setIsAdding(true)
    }
  }

  const handleEdit = async s => {
    setId(s.id)
    setEventsArray(s.eventsArray)
    setDayTwoCount(s.dayTwoCount)
    setParticipation(s.participation)
    setSocialMedia(s.socialMedia)
    setAttendNrg(s.attendNrg)

    setEditId(s.id)
    setIsAdding(false)
  }

  const handleEventArrayDelete = async e => {
    if (window.confirm("Are you sure you want to delete " + e.event + "?") === true) {
      let filteredArray = eventsArray.filter(f => f.event !== e.event)

      let stipendTotal = 0
      let dayTwoCount = 0
      filteredArray.forEach(ea => {
        stipendTotal += ea.stipend
        ea.dayTwo === "Yes" && dayTwoCount++
      })

      const data = {
        eventsArray: filteredArray,
        total: stipendTotal,
        dayTwoCount: dayTwoCount
      }

      const docRef = doc(db, "stipends", id)

      try {
        updateDoc(docRef, data).then(docRef => {
          console.log("Updated")
        })
      } catch (error) {
        console.log("error:", error)
      }
      setEditId("")
    } else {
      console.log("nothing was deleted")
    }
  }

  const handleCancelAdd = e => {
    setIsAdding(false)
  }

  const handleCancelEditStipend = e => {
    setEditId("")
  }

  const handleSubmitAdd = async e => {
    e.preventDefault()

    // check if new value exists before saving
    const found = eventsArray.some(ea => ea.event === event.trim())
    if (found) {
      alert(event.trim().toString() + " already exists")
    } else {
      const newEventStipend = {
        event: event,
        stipend: +stipend,
        dayTwo: dayTwo
      }

      let stipendTotal = 0
      let dayTwoCount = 0
      eventsArray.forEach(ea => {
        stipendTotal += ea.stipend
        ea.dayTwo === "Yes" && dayTwoCount++
      })
      stipendTotal += +stipend
      if (dayTwo === "Yes") {
        dayTwoCount++
      }

      const data = {
        eventsArray: [...eventsArray, newEventStipend],
        total: stipendTotal,
        dayTwoCount: dayTwoCount
      }

      const docRef = doc(db, "stipends", id)

      try {
        updateDoc(docRef, data).then(docRef => {
          console.log("Updated")
        })
      } catch (error) {
        console.log("error:", error)
      }
      setIsAdding(false)
    }
  }

  const handleSubmitSave = async e => {
    e.preventDefault()

    const data = {
      //eventsArray: [...eventsArray],
      //total: stipendTotal
      dayTwoCount: +dayTwoCount,
      participation: +participation,
      socialMedia: +socialMedia,
      attendNrg: +attendNrg
    }

    const docRef = doc(db, "stipends", id)

    try {
      updateDoc(docRef, data).then(docRef => {
        console.log("Updated")
      })
    } catch (error) {
      console.log("error:", error)
    }
    setEditId("")
  }

  const handleGenerate = async e => {
    if (stipends.length) {
      alert("This quarter has already been generated.")
    } else {
      if (window.confirm("Are you sure you want to generate the starting data for the selected quarter?") === true) {
        let players = people.filter(f => f.personIsPlayer === "Yes")

        players.forEach(generatePersonQuarter)
      }
    }
  }

  const generatePersonQuarter = async e => {
    const data = {
      name: e.name.trim(),
      quarter: selectedQuarter.trim(),
      total: null,
      eventsArray: [],
      dayTwoCount: 0,
      participation: 0,
      socialMedia: 0,
      attendNrg: 0
    }

    const ref = collection(db, "stipends")

    await addDoc(ref, data).then().finally(console.log("person quarter generated successfully"))
  }

  return (
    <>
      <span className="page-title">
        <h4>Stipends</h4>
        <span style={{ marginLeft: "auto" }}>
          {selectedQuarter && (
            <button className="btn" onClick={handleGenerate}>
              Generate
            </button>
          )}
        </span>
      </span>
      <label>
        <span>Filter by Quarter:</span>
        <Select options={quarterOptions} onChange={option => setSelectedQuarter(option.value)} />
      </label>
      {isAdding && (
        <form onSubmit={handleSubmitAdd}>
          <strong>{name}</strong>
          <label>
            <span>Event:</span>
            <Select required options={eventOptions} onChange={option => setEvent(option.value)} />
          </label>
          <label>
            <span>Stipend:</span>
            <input required type="number" onChange={e => setStipend(e.target.value)} defaultValue={stipend} />
          </label>
          <label>
            <span>Day 2:</span>
            <Select options={yesNoOptions} onChange={option => setDayTwo(option.value)} defaultValue={{ value: dayTwo, label: dayTwo }} />
            {/* <input required type="number" onChange={e => setStipend(e.target.value)} /> */}
          </label>
          <button type="button" className="btn" onClick={() => handleCancelAdd()}>
            cancel
          </button>
          <button className="btn" style={{ marginLeft: "6px" }}>save</button>
        </form>
      )}
      <div className="event-list">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ flexBasis: "25%" }}>Name</strong>
          <strong style={{ flexBasis: "45%" }}>Events</strong>
          <strong style={{ flexBasis: "15%" }}>Total</strong>
          <strong style={{ flexBasis: "15%" }}></strong>
        </div>
        {stipends &&
          stipends.map(s => (
            <div key={s.id}>
              {editId === s.id && (
                <>
                  <span style={{ display: "flex" }}>
                    <p style={{ flexBasis: "25%" }}>{s.name}</p>

                    <span style={{ flexBasis: "45%" }}>
                      {s.eventsArray &&
                        s.eventsArray.map(a => (
                          <span key={a.event} style={{ display: "flex", height: "2em" }}>
                            <p>
                              {a.event}: {a.stipend}
                              {a.dayTwo == "Yes" ? (
                                <span className="tooltip">
                                  +<span className="tooltiptext">Day 2 Count</span>
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                            <button type="button" style={{ marginLeft: "auto", marginRight: ".7em", height: "fit-content" }} className="btn delete-btn-small" onClick={() => handleEventArrayDelete(a)}>
                              -
                            </button>
                          </span>
                        ))}
                    </span>
                    <span style={{ flexBasis: "15%" }}>
                      <strong>{s.total}</strong>
                    </span>
                    <span style={{ display: "flex", flexBasis: "15%" }}>
                      <button type="button" style={{ height: "fit-content" }} className="btn edit-btn-small" onClick={() => handleCancelEditStipend()}>
                        cancel
                      </button>
                    </span>
                  </span>

                  <form onSubmit={handleSubmitSave}>
                    <span>
                      <label>
                        <span>Day 2 Count: {dayTwoCount}</span>
                        {/* <input type="number" readOnly step="1" onChange={e => setDayTwoCount(e.target.value)} defaultValue={dayTwoCount} min="0" max="20" /> */}
                      </label>
                      <label>
                        <span>Participation:</span>
                        <input type="number" step="0.01" onChange={e => setParticipation(e.target.value)} defaultValue={participation} min="0" max="1" />
                      </label>
                      <label>
                        <span>Social Media:</span>
                        <input type="number" step="0.01" onChange={e => setSocialMedia(e.target.value)} defaultValue={socialMedia} min="0" max="1" />
                      </label>
                      <label>
                        <span>Attend NRG:</span>
                        <input type="number" step="0.01" onChange={e => setAttendNrg(e.target.value)} defaultValue={attendNrg} min="0" max="1" />
                      </label>
                    </span>
                    <button style={{ height: "fit-content" }} className="btn edit-btn-small">
                      save
                    </button>
                  </form>
                </>
              )}
              {editId !== s.id && (
                <>
                  <span style={{ display: "flex" }}>
                    <p style={{ flexBasis: "25%" }}>{s.name}</p>
                    <span style={{ flexBasis: "45%" }}>
                      {s.eventsArray &&
                        s.eventsArray.map(e => (
                          <span key={e.event} style={{ display: "block" }}>
                            {e.event}: {e.stipend}
                            {e.dayTwo == "Yes" ? (
                              <span className="tooltip">
                                +<span className="tooltiptext">Day 2 Bonus</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ))}
                    </span>
                    <strong style={{ flexBasis: "15%" }}>{s.total}</strong>
                    <span style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", flexBasis: "15%" }}>
                      <button style={{ height: "fit-content" }} className="btn edit-btn-small" onClick={() => handleEdit(s)}>
                        edit
                      </button>
                      <button style={{ height: "fit-content", marginTop: ".5em" }} className="btn edit-btn-small" onClick={() => handleAdd(s)}>
                        add
                      </button>
                    </span>
                  </span>
                  <hr />
                  <span style={{ display: "flex", fontSize: ".9em" }}>
                    <p>Day 2 Count: {s.dayTwoCount}</p>
                  </span>
                  <span style={{ display: "flex", fontSize: ".9em" }}>
                    <p>Participation: {s.participation * 100}%</p>
                  </span>
                  <span style={{ display: "flex", fontSize: ".9em" }}>
                    <p>Social Media: {s.socialMedia * 100}%</p>
                  </span>
                  <span style={{ display: "flex", fontSize: ".9em" }}>
                    <p>Attend NRG: {s.attendNrg * 100}%</p>
                  </span>
                </>
              )}
            </div>
          ))}
      </div>
    </>
  )
}
