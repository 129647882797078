import { db, doc, Timestamp } from "../../firebase/config"
import { collection, deleteDoc, addDoc } from "firebase/firestore"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useState } from "react"
import { useCollection } from "../../hooks/useCollection"


export default function SinglesShowcase({ }) {
    const { user } = useAuthContext()
    const [cardName, setCardName] = useState("")
    const [set, setSet] = useState("")
    const [number, setNumber] = useState("")
    const [isAdding, setIsAdding] = useState(false)

    const { documents: singles } = useCollection('singlesShowcase')

    const handleAdd = () => {
        console.log("Add")
        if (isAdding) {
            setIsAdding(false)
        } else {
            setIsAdding(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const ref = collection(db, "singlesShowcase")
        // let today = new Date().toISOString().substr(0, 10)

        // let dateFormat = new Date(today)
        // dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
        // let dateStr = dateFormat.toDateString()


        const data = {
            uid: user.uid,
            cardName: cardName.trim(),
            set: set.trim(),
            number: number.trim(),
            date: Timestamp.fromDate(new Date())
        }

        console.log("data", data)

        await addDoc(ref, data).then(
            setCardName(""),
            setSet(""),
            setNumber("")

        ).finally(console.log("single added"))

        setIsAdding(false)
    }

    const handleDelete = async single => {
        console.log("handleDelete single:", single)

        if (window.confirm("Delete: " + single.cardName + "?") === true) {
            const docRef = doc(db, "singlesShowcase", single.id)
            await deleteDoc(docRef)
            console.log("delete confirmed")
        } else {
            console.log("nothing was deleted")
        }
    }

    return (
        <>
            <span className="page-title">
                <h4>Singles Showcase</h4>
                <span style={{ marginLeft: "auto" }}>
                    {/* <button className="btn edit-button" onClick={handleBorrowerSort}>Sort by borrower</button> */}
                    <button className="btn edit-button" onClick={handleAdd}>add</button>
                </span>
            </span>
            {isAdding && (
                <form onSubmit={handleSubmit}>
                    <label>
                        <span>Card Name:</span>
                        <input required type="text" onChange={e => setCardName(e.target.value)} />
                    </label>
                    <label>
                        <span>Set:</span>
                        <input type="text" onChange={e => setSet(e.target.value)} />
                    </label>
                    <label>
                        <span>Number:</span>
                        <input type="text" onChange={e => setNumber(e.target.value)} />
                    </label>
                    <button className="btn">Save</button>
                </form>
            )}
            <div className="main-list">
                {/* {singles.length === 0 && <p>no cards yet!</p>} */}
                {singles && singles.map(single => (
                    <div style={{ display: "flex" }} key={single.id}>
                        <p>{single.cardName}</p>
                        <p style={{ marginLeft: "auto" }}>{single.set}-</p>
                        <p>{single.number}</p>
                        <button className="btn delete-button" onClick={() => handleDelete(single)}>delete</button>
                        {/* <p>Lender: {card.lender}</p>
            <p>Borrower: {card.borrower}</p>
            <p>Qty: {card.quantity}</p>
            <p>Date: {card.date.toDate().toDateString()}</p> */}
                    </div>
                ))}
            </div>
        </>
    )
}