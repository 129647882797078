import React, { useState, useEffect } from "react"
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Label } from "recharts"
import Select from "react-select"
import { metaAnalysisEventTypeOptions, metaAnalysisFormatOptions, metaAnalysisGLCOptions, metaAnalysisGLCColors, metaAnalysisFills } from "../../Constants"
import { db, doc, Timestamp } from "../../firebase/config"
import { collection, addDoc, updateDoc, query, where, onSnapshot, deleteDoc } from "firebase/firestore"
import { useCollection } from "../../hooks/useCollection"

const MetaAnalysis = () => {
  const [isAddingMetaAnalysisEvent, setIsAddingMetaAnalysisEvent] = useState(false)
  const [eventName, setEventName] = useState("")
  const [eventDate, setEventDate] = useState("")
  const [eventType, setEventType] = useState("")
  const [eventFormat, setEventFormat] = useState("")
  const [deckArray, setDeckArray] = useState([])
  const [deckToAdd, setDeckToAdd] = useState("")
  const [deckToAddCount, setDeckToAddCount] = useState(1)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [userActivity, setUserActivity] = useState([])
  const [title, setTitle] = useState("")

  const [otherDecksList, setOtherDecksList] = useState(null)
  const [maxDecksToShow, setMaxDecksToShow] = useState("8")

  /*Meta Analysis Event Options drop down*/
  const { documents: metaAnalysisEvents } = useCollection("metaAnalysisEvents")
  let metaAnalysisEventsOptions = null
  if (metaAnalysisEvents !== null) {
    metaAnalysisEvents.sort((a, b) => (a.eventDate > b.eventDate ? 1 : b.eventDate > a.eventDate ? -1 : 0))
    metaAnalysisEventsOptions = metaAnalysisEvents.map(m => {
      return { value: m.eventName, label: m.eventName }
    })
  }

  useEffect(() => {
    let ref = collection(db, "metaAnalysisEvents")

    if (selectedEvent !== "") {
      ref = query(ref, where(...["eventName", "==", selectedEvent]))
    }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })

      if (results[0]) {
        /*Sort? */
        results[0].decks.sort(function (a, b) {
          return b.deckCount - a.deckCount
        })

        /*Add the color fill in for each entry. GLC gets specific colors*/
        if (results[0].eventFormat !== "GLC") {
          let deckCounter = 0
          //TODO: Guard?
          let slicedArray = results[0].decks.slice(0, +maxDecksToShow)
          let otherSlicedArray = results[0].decks.slice(+maxDecksToShow, results[0].decks.length)
          if (otherSlicedArray.length > 0) {
            slicedArray.push({ deckName: "Other", deckCount: otherSlicedArray.reduce((n, { deckCount }) => n + deckCount, 0), rank: 0 })
          }

          /* Individual decks  */
          for (let [index, val] of slicedArray.entries()) {
            var fill = metaAnalysisFills.at(index)
            Object.defineProperty(val, "fill", { value: fill })
          }
          /* Other decks  */
          setUserActivity(slicedArray)
          otherSlicedArray.length > 0 ? setOtherDecksList(otherSlicedArray) : setOtherDecksList(null)
        } else {
          results[0].decks.forEach(d => (d.fill = metaAnalysisGLCColors[d.deckName]))
          setUserActivity(results[0].decks.slice(0, +maxDecksToShow))
          setOtherDecksList(null)
        }

        setTitle(results[0].eventName)
        setSelectedEventId(results[0].id)
      }
    })

    return () => unsub()
  }, [selectedEvent, maxDecksToShow])

  let renderLabel = function (entry) {
    return entry.value + "-" + entry.name
  }

  const handleAddMetaAnalysisEvent = () => {
    setIsAddingMetaAnalysisEvent(!isAddingMetaAnalysisEvent)
  }

  const handleSubmitMetaAnalysisEvent = async e => {
    e.preventDefault()

    let dateFormat = new Date(eventDate)
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    let dateStr = dateFormat.toDateString()

    const data = {
      eventName: dateStr + "-" + eventType.trim() + " " + eventFormat.trim(),
      eventDate: Timestamp.fromDate(new Date(dateStr)),
      eventType: eventType.trim(),
      eventFormat: eventFormat.trim(),
      decks: deckArray
    }

    const ref = collection(db, "metaAnalysisEvents")
    await addDoc(ref, data)

    setIsAddingMetaAnalysisEvent(false)
    setDeckToAdd("")
    setDeckToAddCount(1)
    setDeckArray([])
  }

  const handleDeckAdd = async e => {
    let newDeckArray = deckArray ? [...deckArray] : new []()
    newDeckArray.push({ deckName: deckToAdd, deckCount: +deckToAddCount, fill: "", rank: deckArray.length + 1 })
    setDeckArray(newDeckArray)
  }

  const handleDeleteMetaAnalysisEvent = async e => {
    if (selectedEventId === null) {
      alert("Select an event to delete first")
    } else {
      if (window.confirm("Are you sure you want to delete this event?") === true) {
        const docRef = doc(db, "metaAnalysisEvents", selectedEventId)
        await deleteDoc(docRef)
        setUserActivity([])
        setTitle("")
        setOtherDecksList(null)
      } else {
        console.log("Nothing was deleted")
      }
    }
  }

  const handleChangeMaxDecksToShow = async e => {
    let input = window.prompt("Change max decks to show to:")
    if (input > 0) setMaxDecksToShow(+input)
  }

  return (
    <div className="chart" style={{ paddingTop: ".5rem" }}>
      <span style={{ display: "flex", marginLeft: "auto" }}>
        <button className="btn add-button" onClick={handleAddMetaAnalysisEvent}>
          Create Event
        </button>
        <button className="btn delete-button" onClick={handleDeleteMetaAnalysisEvent}>
          Delete Event
        </button>
        <button className="btn edit-btn-small" onClick={handleChangeMaxDecksToShow}>
          {maxDecksToShow}
        </button>
      </span>
      {isAddingMetaAnalysisEvent && (
        <form onSubmit={handleSubmitMetaAnalysisEvent}>
          <label>
            <span>Event Date:</span>
            <input required type="date" onChange={e => setEventDate(e.target.value)} value={eventDate} />
          </label>
          <label>
            <span>Event Type:</span>
            {/* <input required type="text" onChange={e => setEventType(e.target.value)} value={eventType} /> */}
            <Select options={metaAnalysisEventTypeOptions} onChange={option => setEventType(option.value)} />
          </label>
          <label>
            <span>Format:</span>
            <Select options={metaAnalysisFormatOptions} onChange={option => setEventFormat(option.value)} />
          </label>
          <div style={{ borderStyle: "groove", padding: ".5em" }}>
            <label>
              <span>Deck Name:</span>
              {eventFormat === "GLC" ? <Select options={metaAnalysisGLCOptions} onChange={option => setDeckToAdd(option.value)} /> : <input required type="text" onChange={e => setDeckToAdd(e.target.value)} value={deckToAdd} />}
            </label>
            <label>
              <span>Deck Count:</span>
              <input required type="number" onChange={e => setDeckToAddCount(e.target.value)} value={deckToAddCount} />
            </label>
            <button type="button" className="btn" onClick={() => handleDeckAdd()}>
              add
            </button>
            <div className="event-list">
              <div>
                {deckArray &&
                  deckArray.map(d => (
                    <p key={d.deckName}>
                      {d.deckName} - {d.deckCount}
                    </p>
                  ))}
              </div>
            </div>
          </div>
          <button className="btn edit-button" style={{ marginTop: ".8em" }}>
            save
          </button>
        </form>
      )}
      <hr />
      <label>
        <span>Filter by Event:</span>
        <Select options={metaAnalysisEventsOptions} onChange={option => setSelectedEvent(option.value)} />
      </label>
      <p style={{ textAlign: "center" }}>{title}</p>
      <div style={{ width: "auto", height: "350px" }}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <PieChart width={40} height={40}>
            <Pie fontSize={".7em"} cx="50%" cy="50%" data={userActivity} innerRadius={60} outerRadius={80} paddingAngle={1} dataKey="deckCount" nameKey="deckName" label={renderLabel} overflow="visible">
              <Label value="NRG Meta" position="center" />
            </Pie>
            {/* <Legend cx="50%" cy="30%" layout="vertical" verticalAlign="bottom" align="center" /> */}
          </PieChart>
        </ResponsiveContainer>
      </div>
      <span style={{ display: "flex", flexDirection: "column", textAlign: "center", fontSize: ".8em" }}>
        {otherDecksList && <strong>Other</strong>}
        <ul>
          {otherDecksList &&
            otherDecksList.map(d => (
              <li key={d.deckName}>
                {d.deckName}-{d.deckCount}
              </li>
            ))}
        </ul>
      </span>
    </div>
  )
}

export default MetaAnalysis
